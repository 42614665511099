/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { graphql } from 'gatsby';

import { RichText } from 'prismic-reactjs';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Row from '../components/Row';
import Col from '../components/Col';
import StaffItem from '../components/StaffItem';
import SliceZone from '../components/SliceZone';
import HeroCarousel from '../components/HeroCarousel';

import { breakpoint } from '../theme';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  margin: ${rem(50)} auto 0;
  padding-bottom: ${rem(110)};
`;

const Intro = styled(Col)`
  text-align: center;
  margin: ${rem(50)} auto ${rem(75)};
  width: 100%;
  max-width: ${rem(500)};
  font-size: ${rem(18)};

  ${breakpoint('mediumlarge')} {
    max-width: ${rem(680)};
    font-size: ${rem(20)};
  }

  ${breakpoint('large')} {
    margin-top: ${rem(70)};
  }
`;

const Section = styled(Row)`
  margin-bottom: ${rem(40)};
  justify-content: center;

  ${breakpoint('large')} {
    margin-bottom: ${rem(50)};
  }
`;

const Staff = styled(Section)`
  margin-top: ${rem(40)};

  ${breakpoint('large')} {
    margin-top: ${rem(50)};
  }
`;

const GridItem = styled(Col)`
  width: 100%;
  margin-bottom: ${rem(28)};

  ${breakpoint('medium')} {
    width: 50%;
  }

  ${breakpoint('mediumlarge')} {
    width: 33.33%;
  }
`;

function App({ data }) {
  const { contact: page } = data.prismic;
  if (!page) {
    return null;
  }

  return (
    <Layout>
      <SEO
        title={RichText.asText(page.seo_meta_title || page.title)}
        description={page.seo_description}
      />

      {page.hero_items && page.hero_items.length ? (
        <HeroCarousel items={page.hero_items} />
      ) : (
        <Hero
          heading={RichText.asText(page.title)}
          image={page.featured_imageSharp}
          pageTitle
        />
      )}

      {page.content && (
        <Row>
          <Intro>
            <RichText render={page.content} />
          </Intro>
        </Row>
      )}

      <Container>
        <Row>
          <Col>
            <SliceZone slices={page.body} />
          </Col>
        </Row>
        {page.staff_items && page.staff_items.length && (
          <Staff>
            {page.staff_items.map(({ primary: staff }) => (
              <GridItem key={staff.email}>
                <StaffItem data={staff} />
              </GridItem>
            ))}
          </Staff>
        )}
      </Container>
    </Layout>
  );
}

App.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query ContactQuery {
    prismic {
      contact(lang: "en-gb", uid: "contact") {
        _meta {
          uid
        }
        title
        body {
          ... on PRISMIC_ContactBodyCode_block {
            type
            label
            primary {
              codeBlock
            }
          }
          ... on PRISMIC_ContactBodyQuote {
            type
            label
            primary {
              quote
            }
          }
          ... on PRISMIC_ContactBodyText {
            type
            label
            primary {
              text
            }
          }
          ... on PRISMIC_ContactBodyImage {
            type
            label
            primary {
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 1200, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        seo_description
        seo_meta_title
        featured_image
        featured_imageSharp {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        staff_items {
          ... on PRISMIC_ContactStaff_itemsStaff {
            type
            label
            primary {
              email
              image
              imageSharp {
                childImageSharp {
                  fluid(maxWidth: 700, maxHeight: 700, quality: 75) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              name
              phone
              position
            }
          }
        }
      }
    }
  }
`;

export default App;
